<template>
    <v-container fluid>
        <PageHeaderSlot :isLoading="$store.getters.isLoading"> </PageHeaderSlot>
        <v-row>
            <v-col cols="12">
                <Datatable 
                    :isLoading="tableLoading" 
                    :tableHeaders="tableHeaders" 
                    :tableData="tableData" 
                    :page="tablePage"
                    :pageLimit="tableLimit" 
                    :itemTotal="tableItemTotal" 
                    @options-update="onTableOptionsChange($event)"
                ></Datatable>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapActions } from 'vuex';
import Datatable from '@/components/Datatable.vue';

export default {
    name: 'EnquiryList',
    components: {
        Datatable
    },
    data: () => ({
        tableLoading: false,
        tableData: [],
        tablePage: 1,
        tableLimit: 10,
        tableItemTotal: 0,

        // --- static data
        tableHeaders: [
            { text: '建立日期', value: 'create_date', width: 200 },
            { text: '稱呼', value: 'name' },
            { text: '電郵', value: 'email' },
            { text: '電話', value: 'phone' },
            { text: '主題', value: 'subject' },
            { text: '內容', value: 'content' },
        ],
    }),

    methods: {
        ...mapActions(['setDialogMessage', 'setShowDialog', 'setLeaveDialogLink', 'setShowLeaveDialog']),
        async getEnquiryData() {
            this.tableLoading = true;
            try {
                const payload = {
                    limit: this.tableLimit,
                    page: this.tablePage - 1
                };

                const { data, total } = await this.$Fetcher.GetEnquiries(payload);
                this.tableItemTotal = total;
                this.tableData = data.map(item => {
                    return {
                        id: item.id,
                        create_date: item.create_date,
                        name: item.name,
                        email: item.email,
                        phone: item.phone,
                        subject: item.subject,
                        content: item.content,
                    }
                })
            } catch (err) {
                this.$common.error(err);
            } finally {
                this.tableLoading = false;
            }
        },

        onTableOptionsChange(options) {
            if (options.itemsPerPage !== this.tableLimit) {
                this.tablePage = 1; // reset to first page if view options changed
            } else {
                this.tablePage = options.page;
            }

            this.tableLimit = options.itemsPerPage;
            this.getEnquiryData();
        },
    },
    async created() {
        this.getEnquiryData();
    },

    // ------ navigation guard ------
    beforeRouteLeave(to, from, next) {
        if (this.$store.getters.isLoading) {
            this.$store.dispatch('toggleAlertMessage', {
                show: true,
                message: 'processing',
                type: 'error',
                refresh: false,
                redirect: ''
            });
            next(false);
        } else if (this.$store.getters.dataIsUpdated) {
            this.setLeaveDialogLink({ name: to.name });
            this.setShowLeaveDialog(true);
            next(false);
        } else {
            next();
        }
    },
}
</script>
  